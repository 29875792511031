* {
	padding: 0;
	margin: 0;
}

.center {
	text-align: center;
}

.left {
	text-align: left !important;
}

.full {
	width: 100%;
}

.mt60 {
	margin-top: 5%;
}

header {
	background: linear-gradient(to right, #e10612, #9500ff);
	padding: 15px;
	// margin-bottom: 5%;

	& .logo {
		background-color: #fff;
		border-radius: 50px;
		text-align: center;
		padding: 5px;
		width: 140px;

		& img {
			width: 80%;
		}
	}
}
