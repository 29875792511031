::-webkit-scrollbar {
	// width: 2px;
	// height: 2px;
	// background: #ccc;
	display: none; /* Ocultar scroll */
}

::-webkit-scrollbar-thumb {
	background: linear-gradient(to right, red, purple);
	border-radius: 2px;
}

@font-face {
	font-family: 'Poppins-Regular.ttf';
	src: url(../../assets/fonts/Poppins-Regular.ttf);
}
@font-face {
	font-family: 'Poppins-Bold.ttf';
	src: url(../../assets/fonts/Poppins-Bold.ttf);
}

#streaming {
	// height: 100vh;
	display: grid;
	// background-repeat: no-repeat;
	// background-position: top center;
	// background-size: cover;
	padding: 60px 0;

	&::after {
		content: '';
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		position: fixed;
		z-index: -1;
		background-repeat: no-repeat;
		// background-position-y: 100%;
		// background-position-x: center;
		background-size: cover;
		background-position: top;
		background-image: url(../../assets/images/bg.svg);
		// background-image: url(../../assets/images/bg.png);
	}

	& p {
		font-family: 'Poppins-Regular.ttf';
		margin-bottom: 5px;
		color: #fff;
		font-size: 18px;
		text-align: center;
	}

	& .abner {
		border: 5px solid #19f3f3;
		border-radius: 15px;
	}

	& .contenedor-streaming {
		padding: 56.25% 0 0 0;
		position: relative;

		& .estilo-streaming {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border: none;
		}
	}

	& .contentenedor-chat {
		height: 100%;
	}

	& h1 {
		margin-top: 60px;
		margin-bottom: 60px;
		font-size: 45px;
		font-family: 'Poppins-Bold.ttf';
		text-align: center;
		color: #fff;

		& span {
			color: #19f3f3;
		}
	}

	& h2 {
		color: #19f3f3;
		font-size: 35px;
		font-family: 'Poppins-Bold.ttf';
		text-align: left;
	}

	& .texto-evento {
		text-align: left;
	}

	& .contenedor {
		background-color: #0e0e1a;
		border-radius: 15px;
		padding: 45px;

		& .flex {
			display: flex;

			& .izq {
				margin-right: 20px;

				& .linea {
					width: 6px;
					height: 300px;
					background: linear-gradient(to bottom, red, purple);
				}
			}

			& .der {
				display: block;
				& small {
					color: #19f3f3;
					font-family: 'Poppins-Bold.ttf';
					font-size: 32px;
					display: grid;
				}

				& p {
					text-align: left;
				}
			}
		}
	}

	// & button {
	// 	background-color: #00f5d8;
	// 	font-family: 'Poppins-Bold.ttf';
	// 	color: #707070;
	// 	padding: 15px;
	// 	width: 220px;
	// 	font-size: 18px;
	// 	margin: auto;
	// 	margin-top: 1%;
	// }
}

@media only screen and (max-width: 600px) {
	#streaming {
		&::after {
			background-position: center;
		}
		& .logos {
			width: 90%;
		}

		& h1 {
			font-size: 38px;
		}
		& h2 {
			text-align: center;
		}

		& .texto-evento {
			text-align: center;
		}
		& .contenedor {
			& .flex {
				& .izq {
					& .linea {
						display: none;
					}
				}
				& .der {
					& .h2 {
						text-align: center;
					}
					& small {
						text-align: center;
					}
					& p {
						text-align: center;
					}
				}
			}
		}
		// & .header {
		// 	& img {
		// 		width: 25%;
		// 	}
		// }
		// & .contentenedor-chat {
		// 	height: 400px;
		// }
	}
}
@media only screen and (min-width: 600px) {
	#streaming {
		// & .header {
		// 	& img {
		// 		width: 15%;
		// 	}
		// }
		// & .contentenedor-chat {
		// 	height: auto;
		// }
	}
}
@media only screen and (min-width: 768px) {
	#streaming {
		// & .header {
		// 	& img {
		// 		width: 25%;
		// 	}
		// }
	}
}
@media only screen and (min-width: 992px) {
	#streaming {
		// & .header {
		// 	& img {
		// 		width: 10%;
		// 	}
		// }
		// & .contentenedor-chat {
		// 	height: 100%;
		// }
	}
}
@media only screen and (min-width: 1200px) {
	#streaming {
	}
}
