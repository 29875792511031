@font-face {
	font-family: 'Poppins-Regular.ttf';
	src: url(../../assets/fonts/Poppins-Regular.ttf);
}
@font-face {
	font-family: 'Poppins-Bold.ttf';
	src: url(../../assets/fonts/Poppins-Bold.ttf);
}

#login {
	display: grid;
	&::after {
		content: '';
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		position: fixed;
		z-index: -1;
		background-repeat: no-repeat;
		// background-position-y: 100%;
		// background-position-x: center;
		background-size: cover;
		background-position: top;
		background-image: url(../../assets/images/bg.svg);
	}

	& form {
		font-family: 'Poppins-Regular.ttf';
		color: #fff;
		text-align: center;
		background: rgba($color: #000000, $alpha: 0.6);
		height: 100vh;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 15px;

		& .texto {
			font-size: 22px;
		}

		& .abner {
			padding: 1rem;
			position: relative;
			background: linear-gradient(to right, red, purple);
			padding: 3px;
		}

		& .MuiInputBase-input {
			font-family: 'Poppins-Regular.ttf';
			background-color: #fff;
			color: #707070 !important;
			font-size: 18px;
			border: red !important;
		}

		& .MuiInput-underline {
			&::after {
				border-bottom: 2px solid #707070 !important;
			}

			&::before {
				border-bottom: 2px solid #707070 !important;
			}
		}

		& .MuiFormLabel-root {
			&.Mui-focused {
				font-family: 'Poppins-Regular.ttf';
				color: #707070 !important;
			}
		}

		& .MuiInputLabel-outlined.MuiInputLabel-shrink {
			transform: translate(0px, -18px) scale(1) !important;
			font-family: 'Poppins-Regular.ttf';
			color: #707070 !important;
		}

		& .MuiFormLabel-root {
			color: #707070 !important;
		}

		& .MuiFilledInput-underline {
			background: linear-gradient(90deg, #f05a5d 0%, #70379f 100%) !important;

			// &::before,
			// &::after {
			// }
		}

		& .MuiOutlinedInput-notchedOutline {
			border: #707070 !important;
		}

		& .MuiFormLabel-root.Mui-focused {
			font-weight: normal;
			font-size: 16px;
		}

		& button {
			width: 100%;
			text-align: center;
			color: #fff;
			text-transform: uppercase;
			font-size: 22px;
			padding: 15px;
			background: linear-gradient(to right, #e10612, #9500ff);
			border: none;
			transition: all 0.3s ease-in;
			cursor: pointer;

			&:hover {
				transform: scale(0.97);
			}
		}
	}
}

@media only screen and (max-width: 600px) {
	header {
		margin-bottom: 5%;
	}
	#login {
		& .img-izq {
			width: 70%;
		}
		& form {
			height: auto;
			padding-bottom: 60px;
		}
	}
}

@media only screen and (min-width: 600px) {
	header {
		margin-bottom: 0%;
	}
	#login {
		& .img-izq {
			width: 70%;
		}
		& form {
			height: auto;
			padding-bottom: 60px;
			& .texto {
				font-size: 15px;
			}
		}
	}
}

@media only screen and (min-width: 768px) {
	#login {
		// overflow: hidden;
		// height: auto;

		// & .header-img {
		// 	width: 100%;
		// }
		// & .img-izq {
		// 	width: 75%;
		// 	margin-top: 5%;
		// }

		// & .contenedor {
		// 	height: 40vh;
		// 	display: block;
		// 	& img {
		// 		width: 90%;
		// 	}
		// }
	}
}

@media only screen and (min-width: 992px) {
	#login {
		& .img-izq {
			width: 70%;
		}
		& form {
			height: 100vh;
			& .texto {
				font-size: 22px;
			}
		}
	}
}
